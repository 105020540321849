<template>
    <div id="page-households">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Households</h1>
                </div>

                <div class="col-12">
                    <app-tabs v-model="tab" :tabs="tabs"></app-tabs>
                </div>
            </div>
        </div>

        <router-view @open-sidebar="openSidebar" @close-sidebar="closeSidebar"></router-view>
    </div>
</template>

<script>
import appTabs from '@/components/app-tabs'

export default {
    components: {
        appTabs,
    },

    data() {
        return {
            tabs: [
                {
                    id: 'local',
                    name: 'households-local',
                    title: 'Local Households',
                },
                {
                    id: 'kaltura',
                    name: 'households-kaltura',
                    title: 'Kaltura',
                },
            ],
        }
    },

    computed: {
        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.name == tab.name)

                // console.log('get', JSON.parse(JSON.stringify(tab)))

                if (!tab) {
                    this.tab = {
                        name: this.tabs[0].name,
                        replace: true,
                    }

                    // console.log({ name: this.tabs[0].name, replace: true })
                }

                return tab
            },

            set(tab) {
                // console.log('set', JSON.parse(JSON.stringify(tab)))

                this.$router.push({ name: tab.name, replace: true })
            },
        },
    },

    mounted() {
        // console.log('households mounted...')
    },

    methods: {
        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.$emit('close-sidebar')
        },
    },

    beforeRouteUpdate(to, from, next) {
        this.closeSidebar()
        next()
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
#page-households {
    margin-top: 48px;

    h1 {
        margin-bottom: 40px;
    }

    .app-tabs {
        flex-grow: 1;
        margin-bottom: 34px;
    }
}

@media (max-width: $tablet-size) {
    #page-households {
        h1 {
            margin-bottom: 48px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-households {
        margin-top: 32px;
        
        h1 {
            margin-bottom: 32px;
        }
        
        .app-tabs {
            flex-grow: 0;
            margin-bottom: 16px;
        }
    }
}
</style>