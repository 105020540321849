<template>
    <div class="app-tabs" :class="{ 'ddt': dropdownOnTablet }">
        <app-dropdown-select v-model="value_tab"
            :options="tabs"

            key-value="id"
            key-title="title"
        ></app-dropdown-select>

        <button class="btn-tab"
            v-for="tab in tabs" :key="tab.id"

            :class="{ active: value && tab[valueId] == value[valueId] }"

            @click="changeTab(tab)"
        >{{ tab.title }}</button>
    </div>
</template>

<script>
import appDropdownSelect from '@/components/app-dropdown-select'

export default {
    props: {
        value:         { default: null },
        tabs:          { type: [Array, Object], required: true },
        valueId:       { default: 'id' },

        dropdownOnTablet: { type: Boolean, default: false },
    },

    components: {
        appDropdownSelect,
    },

    methods: {
        changeTab(tab) {
            if (!this.value || tab[this.valueId] != this.value[this.valueId]) {
                this.$emit("input", tab)
                this.$emit("change", tab)
            }
        }
    },

    computed: {
        value_tab: {
            get() {
                return this.value ? this.value[this.valueId] : null
            },
            set(val) {
                const tab = this.tabs.find(tab => tab[this.valueId] == val)

                if (tab) {
                    this.changeTab(tab)
                }
            },
        },
    },
}
</script>

<style lang="scss">
.app-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    .btn-tab {
        position: relative;
        margin-right: 48px;
        font-size: 32px;
        font-weight: bold;
        line-height: 40px;
        text-align: left;
        color: var(--color-tab);
        transition: color $transition-duration-primary;

        &:last-child {
            margin-right: 0;
        }

        &::after {
            position: absolute;
            display: block;
            width: 0;
            height: 4px;
            background-color: var(--color-tab);
            border-radius: $border-radius-primary;
            transition: width $transition-duration-primary;
            content: '';
        }

        &:hover,
        &.active {
            &::after {
                width: 80px;
            }
        }

        &:hover {
            color: var(--color-tab-hover);

            &::after {
                background-color: var(--color-tab-hover);
            }
        }

        &.active {
            color: var(--color-tab-active);

            &::after {
                background-color: var(--color-tab-active);
            }
        }
    }

    .app-dropdown-select {
        display: none;
    }
}

@mixin enable-dropdown-mode() {
    width: auto;        // for IE
    width: fit-content;

    .btn-tab {
        font-size: 22px;
        line-height: 40px;
        margin: 0;
        white-space: nowrap;

        margin-right: 8px;

        &:after {
            bottom: 0;
        }

        &.active,
        &:hover {
            &::after {
                width: 40px;
            }
        }

        &:not(.active) {
            display: none;
        }
    }

    .app-dropdown-select {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 32px);
        height: 40px;
        z-index: $z-index-dropdown;

        .trigger {
            border: none;
            background: transparent;

            label,
            .title {
                visibility: hidden;
            }
        }

        .menu {
            min-width: 180px;

            .options {
                .option {
                    &.active {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-tabs {
        .btn-tab {
            margin-right: 48px;
        }

        &.ddt {
            @include enable-dropdown-mode();

            .btn-tab {
                font-size: 32px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-tabs {
        @include enable-dropdown-mode();

        &.ddt {
            .btn-tab {
                font-size: 22px;
            }
        }
    }
}
</style>